import React from "react";
import { Container, Wrapper, Title } from "./styled";
import Logo from "../../assets/logo.png";
export default function Header() {
  return (
    <Container>
      <Wrapper></Wrapper>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path d="M0,256L10.4,234.7C20.9,213,42,171,63,160C83.5,149,104,171,125,192C146.1,213,167,235,188,250.7C208.7,267,230,277,250,282.7C271.3,288,292,288,313,272C333.9,256,355,224,376,229.3C396.5,235,417,277,438,272C459.1,267,480,213,501,176C521.7,139,543,117,563,133.3C584.3,149,605,203,626,229.3C647,256,668,256,689,229.3C709.6,203,730,149,751,122.7C772.2,96,793,96,814,117.3C834.8,139,856,181,877,176C897.4,171,918,117,939,117.3C960,117,981,171,1002,192C1022.6,213,1043,203,1064,197.3C1085.2,192,1106,192,1127,192C1147.8,192,1169,192,1190,181.3C1210.4,171,1231,149,1252,165.3C1273,181,1294,235,1315,213.3C1335.7,192,1357,96,1377,85.3C1398.3,75,1419,149,1430,186.7L1440,224L1440,320L1429.6,320C1419.1,320,1398,320,1377,320C1356.5,320,1336,320,1315,320C1293.9,320,1273,320,1252,320C1231.3,320,1210,320,1190,320C1168.7,320,1148,320,1127,320C1106.1,320,1085,320,1064,320C1043.5,320,1023,320,1002,320C980.9,320,960,320,939,320C918.3,320,897,320,877,320C855.7,320,835,320,814,320C793,320,772,320,751,320C730.4,320,710,320,689,320C667.8,320,647,320,626,320C605.2,320,584,320,563,320C542.6,320,522,320,501,320C480,320,459,320,438,320C417.4,320,397,320,376,320C354.8,320,334,320,313,320C292.2,320,271,320,250,320C229.6,320,209,320,188,320C167,320,146,320,125,320C104.3,320,83,320,63,320C41.7,320,21,320,10,320L0,320Z"></path>
      </svg>
      <Title>
        <img src={Logo} alt="Logo" />
        <h1>Worlds Cup 2022</h1>
      </Title>
    </Container>
  );
}
